import React from "react";
import locales from "../constants";
import { Layout, ContactFormDark } from "../components";
import { Link } from "gatsby";
import $ from "jquery";

const KampaniaYoutubeAdsPage = ({ location: { pathname } }) => {
  if (typeof window !== `undefined`) {
    (function ($) {
      $.fn.shuffleLetters = function (prop) {
        var options = $.extend(
          {
            step: 1, // How many times should the letters be changed
            fps: 30, // Frames Per Second
            text: "", // Use this text instead of the contents
            callback: function () {}, // Run once the animation is complete
          },
          prop
        );

        return this.each(function () {
          var el = $(this),
            str = "";

          // Preventing parallel animations using a flag;
          if (el.data("animated")) {
            return true;
          }
          el.data("animated", true);

          if (options.text) {
            str = options.text.split("");
          } else {
            str = el.text().split("");
          }

          // The types array holds the type for each character;
          // Letters holds the positions of non-space characters;

          var types = [],
            letters = [];

          // Looping through all the chars of the string

          for (var i = 0; i < str.length; i++) {
            var ch = str[i];

            if (ch === " ") {
              types[i] = "space";
              continue;
            } else if (/[a-z]/.test(ch)) {
              types[i] = "lowerLetter";
            } else if (/[A-Z]/.test(ch)) {
              types[i] = "upperLetter";
            }

            letters.push(i);
          }

          el.html("");

          // Self executing named function expression:

          (function shuffle(start) {
            // This code is run options.fps times per second
            // and updates the contents of the page element

            var i,
              len = letters.length,
              strCopy = str.slice(0); // Fresh copy of the string

            if (start > len) {
              // The animation is complete. Updating the
              // flag and triggering the callback;

              el.data("animated", false);
              options.callback(el);
              return;
            }

            // All the work gets done here
            for (i = Math.max(start, 0); i < len; i++) {
              // The start argument and options.step limit
              // the characters we will be working on at once

              if (i < start + options.step) {
                // Generate a random character at thsi position
                strCopy[letters[i]] = randomChar(types[letters[i]]);
              } else {
                strCopy[letters[i]] = "";
              }
            }

            el.text(strCopy.join(""));

            setTimeout(function () {
              shuffle(start + 1);
            }, 2550 / options.fps);
          })(-options.step);
        });
      };

      function randomChar(type) {
        var pool = "";

        if (type === "lowerLetter") {
          pool = "abcdefghijklmnopqrstuvwxyz";
        } else if (type === "upperLetter") {
          pool = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
        }

        var arr = pool.split("");
        return arr[Math.floor(Math.random() * arr.length)];
      }
    })($);

    $(function () {
      var container = $("#shuffle");
      container.shuffleLetters();

      function shuffle(text) {
        container.shuffleLetters({
          text: text,
        });
      }

      var arr = [
        "wybranych kanałach",
        "wybranych filmach",
        "wybranej tematyce filmów",
        "zainteresowań odbiorców",
        "danych demograficznych",
        "lista remarketingowych",
        "niestandardowych zainteresowań",
      ];
      var i = 0;
      setInterval(function () {
        shuffle(arr[i++ % arr.length]);
      }, 2000);
    });
  }

  return (
    <Layout
      seo={{
        title: "Youtube Ads - reklama wideo na Youtube",
        description:
          "W naszej agencji specjalizujemy się w tworzeniu kampanii wideo na YouTube, które pomogą Ci zwiększyć zasięg Twojej marki oraz przyciągnąć nowych klientów. Kampanie wideo na platformie YouTube to jedna z najskuteczniejszych form reklamy online.",
        href: pathname,
        lang: "pl",
      }}
    >
      <span className="anchor" id="home"></span>
      <div className="subpage-header subpage-header--single-offer">
        <h1 className="subpage-header__title">
          Kampanie wideo <br /> <span>na YouTube</span>
        </h1>
      </div>

      <div className="single-offer-menu-container">
        <div className="row">
          <div className="col-lg-3">
            <Link to="/uslugi/#ppc">
              <div className="back-wrapper">
                <span className="back-wrapper__arrow"></span>
                <p className="back-wrapper__title">Usługi</p>
              </div>
            </Link>
            <p className="nav-title">Specjalizacje:</p>
            <ul>
              <li>
                <Link to="/audyt-kampanii-google-facebook-ads/">
                  Audyt kampanii <br /> Google/Facebook Ads
                </Link>
              </li>
              <li>
                <Link to="/kampanie-googleads-search/">
                  Kampanie Google Ads search
                </Link>
              </li>
              <li className="current">
                <a href="#home">Kampanie Youtube Ads</a>
              </li>
              <li>
                <Link to="/kampanie-w-sieci-reklamowej-gdn/">Kampanie GDN</Link>
              </li>
              <li>
                <Link to="/kampanie-produktowe-product-listing-ads/">
                  Kampanie PLA
                </Link>
              </li>
              <li>
                <Link to="/tiktok-ads/">Kampanie TikTok Ads</Link>
              </li>
              <li>
                <Link to="/kampanie-facebook-ads/">Kampanie Facebook Ads</Link>
              </li>
              <li>
                <Link to="/kampanie-na-instagramie/">
                  Kampanie na Instagramie
                </Link>
              </li>
              <li>
                <Link to="/kampanie-linkedin-ads/">Kampanie LinkedIn</Link>
              </li>
              <li>
                <Link to="/banery-statyczne-i-dynamiczne/">
                  Banery statyczne i dynamiczne
                </Link>
              </li>
              <li>
                <Link to="/kampanie-zagraniczne/">
                  Kampanie PPC na rynki zagraniczne
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <section className="single-offer-section-text-left single-offer-section-text-left--cat-2">
        <div className="row">
          <div className="col-xl-8 offset-xl-4 col-lg-9 offset-lg-3">
            <div className="row align-items-center">
              <div className="col-lg-6 text-col">
                <h2 className="text-col__title mb-40">
                  Wzbudzaj emocje. <br />
                  Angażuj użytkowników
                </h2>
                <p className="text-col__desc">
                  Czy wiedziałeś, że YouTube to druga najpopularniejsza
                  wyszukiwarka na świecie?Liczba użytkowników portalu YouTube w
                  Polsce wynosi ponad 19 milionów, a użytkownik spędza na
                  YouTube średnio 17:53 dziennie! Dlatego nie zdziwi Cię pewnie
                  stwierdzenie, że YouTube to miejsce, gdzie marki powinny się
                  znaleźć, ponieważ jej odbiorcy już (od dawna) tam są. Jest to
                  równocześnie jedyna forma reklamy, która łączy w sobie zarówno
                  obraz, dźwięk i animację, co pozwala wzbudzenie emocji,
                  zbudowanie więzi z użytkownikiem i wpływa na większą
                  zapamiętywalność reklamy.
                  <br />
                  <br />
                  Na YouTube mamy wiele możliwości kierowania reklam, kilka
                  ciekawych formatów i dużą dowolność w zakresie treści
                  materiałów filmowych. Ponieważ YouTube nieustannie ewoluuje,
                  lista formatów reklam i typów kampanii jest również cały czas
                  rozbudowywana z uwzględnieniem zmieniających się potrzeb
                  użytkowników i reklamodawców.
                </p>
              </div>
              <div className="col-lg-6 img-col">
                <img
                  className="img-fluid img-col__image"
                  src={require("../assets/images/youtube-img-1.jpg")}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="single-offer-section-big-img single-offer-section-big-img--youtube-ads">
        <div className="row">
          <div className="col-xl-7 col-lg-8 offset-lg-4">
            <h2 className="single-offer-section-big-img__title">
              Dlaczego Youtube Ads?
            </h2>
            <div className="image-container">
              <img
                className="single-offer-section-big-img__image image image--desktop"
                src={require("../assets/images/kampania-youtube-graph.png")}
                alt=""
              />
              <img
                className="single-offer-section-big-img__image image image--mobile"
                src={require("../assets/images/kampania-youtube-mobile.png")}
                alt=""
              />
              <p className="image-container__text text text--1">
                Reklamy wzbudzajace emocje
              </p>
              <p className="image-container__text text text--2">
                Natychmiastowa <br /> widoczność
              </p>
              <p className="image-container__text text text--3">
                Precyzyjne <br /> targetowanie
              </p>
              <p className="image-container__text text text--4">
                Większa <br /> zapamiętywalność
              </p>
              <p className="image-container__text text text--5">
                Przejrzyste <br /> wyniki
              </p>
              <p className="image-container__text text text--6">
                Połączenie obrazu, dzwięku <br />i animacji
              </p>
              <ul className="big-bullets list-mobile">
                <li>Budowa i struktura kont</li>
                <li>
                  Implementacja analityki (Google Analytics, Google Merchant
                  Center, pixele)
                </li>
                <li>Grupy odbiorców</li>
                <li>Listy remarketingowe i cele</li>
                <li>Ustawienia kampanii i zestawów reklam</li>
                <li>Rozszerzenia</li>
                <li>Słowa kluczowe</li>
                <li>Reklamy</li>
                <li>Analizaca wykorzystania najnowszych rekomendacji</li>
                <li>
                  <strong>Zalecenia!</strong>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="single-offer-section-text single-offer-section-text--youtube-ads">
        <div className="row">
          <div className="col-lg-8 offset-lg-3 text-col">
            <h2 className="text-col__title">
              <span>
                Targetuj reklamę <br /> YouTube po:
              </span>{" "}
              <br />
              <span id="shuffle">wybranych kanałach</span>
            </h2>
          </div>
        </div>
      </section>
      <section className="single-offer-section-icons-timeline">
        <div className="row">
          <div className="col-xl-7 col-lg-8 offset-lg-4">
            <h2 className="single-offer-section-icons-timeline__title">
              Różnorodne formaty
            </h2>
            <p>
              Działania prowadzone przez marki na YouTube mogą precyzyjnie
              odpowiadać różnym potrzebom biznesowym – od budowania świadomości,
              przez tworzenie społeczności, po generowanie sprzedaży. Realizując
              te cele, możemy wykorzystać różne formaty reklamowe, takie jak:
            </p>
            <div className="timeline-icons">
              <div className="single-item">
                <img
                  src={require("../assets/images/yt-type-1.jpg")}
                  alt=""
                  className="single-item__image"
                />
                <div className="text-container">
                  <p className="text-container__title">Masthead</p>
                  <p className="text-container__desc">
                    Dla szybkiego podniesienia rozpoznawalności produktu lub
                    marki można wykupić baner na stronie głównej portalu YouTube
                    na jeden dzień. Jest to format kosztowny, zwykle używany
                    przez duże marki.
                  </p>
                </div>
              </div>
              <div className="single-item">
                <img
                  src={require("../assets/images/yt-type-2.jpg")}
                  alt=""
                  className="single-item__image"
                />
                <div className="text-container">
                  <p className="text-container__title">Trueview In-Stream</p>
                  <p className="text-container__desc">
                    Każdy reklamodawca może jednak skorzystać z reklam wideo
                    przed innymi filmami lub w przerwie filmu. Taki format
                    nazywa się TrueView In-Stream i jest płatny za wyświetlenie,
                    które kosztuje nawet kilka groszy.
                  </p>
                </div>
              </div>
              <div className="single-item">
                <img
                  src={require("../assets/images/yt-type-3.jpg")}
                  alt=""
                  className="single-item__image"
                />
                <div className="text-container">
                  <p className="text-container__title">Trueview for Action</p>
                  <p className="text-container__desc">
                    Obecnie można skorzystać z pomijalnej reklamy wideo, żeby
                    wywołać pożądaną akcję - wtedy oprócz reklamy wideo mamy
                    przycisk z wezwaniem do działania i panel zapraszający do
                    odwiedzenia strony na końcu filmu. Taki format jest
                    rekomendowany, jeśli kampania ma generować konkretne
                    konwersje na stronie.
                  </p>
                </div>
              </div>
              <div className="single-item">
                <img
                  src={require("../assets/images/yt-type-4.jpg")}
                  alt=""
                  className="single-item__image"
                />
                <div className="text-container">
                  <p className="text-container__title">Produktowa</p>
                  <p className="text-container__desc">
                    Żeby przedstawić swoje produkty sklepu e-commerce można
                    połączyć reklamę wideo z zaprezentowaniem produktów, które w
                    nim występują. Taki typ reklamy ma nakłonić do zakupu
                    produktów prezentowanych w formie filmu, np. podczas
                    unpackingu.
                  </p>
                </div>
              </div>
              <div className="single-item">
                <img
                  src={require("../assets/images/yt-type-5.jpg")}
                  alt=""
                  className="single-item__image"
                />
                <div className="text-container">
                  <p className="text-container__title">Bumper</p>
                  <p className="text-container__desc">
                    Żeby podnieść rozpoznawalność lub przedstawić nowy produkt
                    można skorzystać z reklamy typu bumper - niepomijalnej, 6
                    sekundowej reklamy wideo.
                  </p>
                </div>
              </div>
            </div>
            <div className="big-img-container">
              <h2 className="big-img-container__title">Sekwencja reklamy</h2>
              <p className="big-img-container__desc">
                czyli możliwość tworzenia i opowiadania historii za pomocą
                reklam konkretnemu użytkownikowi. Układanie reklam w sekwencje z
                konkretnymi etapami pozwala zbudować wokół marki lub produktu
                opowieść, która wciągnie i zainteresuje potencjalnego klienta.
              </p>
              <img
                src={require("../assets/images/yt-big-ad.jpg")}
                alt=""
                className="big-img-container__image img-fluid"
              />
              <p className="big-img-container__desc">
                Mając do wyboru tak wiele rodzajów reklam spełniających różne
                cele można na YouTube przejść z potencjalnym klientem cały lejek
                sprzedażowy. Budując świadomość reklamami typu bumper, osobom
                wstępnie zainteresowanym pokazując pomijalne reklamy i zamykając
                transakcje przy pomocy reklam trueview for action. Brzmi
                ciekawie, prawda?
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="single-offer-section-clients">
        <div className="row">
          <div className="col-xl-8 offset-xl-3 col-lg-9 offset-lg-3">
            <div className="section-header aligncenter">
              <span className="section-header__small-text">
                Wiemy, jak prowadzić skuteczne kampanie YouTube
              </span>
              <h2 className="section-header__title">Zaufali nam</h2>
            </div>
            <div className="row align-items-center justify-content-center">
              <div className="col-md-3 col-sm-4 col-6 client-col">
                <div className="client-col__logo-wrapper">
                  <img
                    className="img-fluid client-col__logo"
                    src={require("../assets/img/clients/thailand.png")}
                    alt=""
                  />
                </div>
              </div>
              <div className="col-md-3 col-sm-4 col-6 client-col">
                <div className="client-col__logo-wrapper">
                  <img
                    className="img-fluid client-col__logo"
                    src={require("../assets/img/clients/gmr.jpg")}
                    alt=""
                  />
                </div>
              </div>
              <div className="col-md-3 col-sm-4 col-6 client-col">
                <div className="client-col__logo-wrapper">
                  <img
                    className="img-fluid client-col__logo"
                    src={require("../assets/img/clients/komputronik.png")}
                    alt=""
                  />
                </div>
              </div>
              <div className="col-md-3 col-sm-4 col-6 client-col">
                <div className="client-col__logo-wrapper">
                  <img
                    className="img-fluid client-col__logo"
                    src={require("../assets/img/clients/krakow.png")}
                    alt=""
                  />
                </div>
              </div>
              <div className="col-md-3 col-sm-4 col-6 client-col">
                <div className="client-col__logo-wrapper">
                  <img
                    className="img-fluid client-col__logo"
                    src={require("../assets/img/clients/techland.png")}
                    alt=""
                  />
                </div>
              </div>
              <div className="col-md-3 col-sm-4 col-6 client-col">
                <div className="client-col__logo-wrapper">
                  <img
                    className="img-fluid client-col__logo"
                    src={require("../assets/img/clients/greenpoint.png")}
                    alt=""
                  />
                </div>
              </div>
              <div className="col-md-3 col-sm-4 col-6 client-col">
                <div className="client-col__logo-wrapper">
                  <img
                    className="img-fluid client-col__logo"
                    src={require("../assets/img/clients/steelseries.png")}
                    alt=""
                  />
                </div>
              </div>
              <div className="col-md-3 col-sm-4 col-6 client-col">
                <div className="client-col__logo-wrapper">
                  <img
                    className="img-fluid client-col__logo"
                    src={require("../assets/img/clients/imperial-stawowa.png")}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="single-offer-section-contact">
        <div className="form-wrapper">
          <h2 className="single-offer-section-contact__title mb-40">
            Skontaktuj się z nami! <br />
            Wypełnij <span>formularz</span>
          </h2>
          <ContactFormDark locales={locales["pl"]} messageRequired={false} />
        </div>
        <img
          className="img-fluid single-offer-section-contact__hand"
          src={require("../assets/images/home-contact-hand-right.png")}
          alt=""
        />
      </section>
    </Layout>
  );
};
export default KampaniaYoutubeAdsPage;
